@import "_colors";

.Popup{
  position:fixed;
  top:0px;
  left:0px;
  z-index:99999999;
  display:none;
  width:100%;
  height:100vh;
  justify-content: center;
  align-items:center;
  background:rgba(0,0,0,0.8);



  .popupHolder{
    position:relative;

    display:block;
    width:400px;
    height:auto;
    max-width:90%;
    max-height:90%;
    overflow-y:auto;

    padding:20px;
    background-color:#fcfcfc;
    border:1px solid #ececec;
    box-shadow:0px 10px 20px rgba(0,0,0,0.5);

    .close{
      position:absolute;
      right:10px;
      top:10px;

      width:40px;
      height:40px;
      font-size:36px;
      color:#666;
      border:0px;
      outline:0px;
      background:transparent;
      cursor:pointer;

    }

    .line{
      display:block;
      border-bottom:1px solid #ececec;
      padding:5px;
      font-size:12px;

      &:first-of-type{
        border-top:1px solid #ececec;
      }
    }

    .section{
      display:block;
      margin-top:20px;
      text-align:left;
      b{
        font-size:12px;
        display:block;
        text-transform:uppercase;
        letter-spacing:1px;
        margin-bottom:5px;
      }
    }
  }

  &.show{
    display:flex;
  }

  .details{
    text-align:center;

    img{
      max-width:100%;
    }

    p{
      text-align:left;
    }
  }

}

.optionsList{
  list-style:none;
  margin:0px;
  padding:0px;

  li{
    padding:16px;
    list-style:none;
    font-size:14px;
    cursor:pointer;
    text-align:center;
    cursor:pointer;
    border-bottom:1px solid #ececec;

    transition:all 0.3s ease-in-out;

    &:hover{
      font-weight:bold;
    }

    &:last-child{
      border-bottom:0px;
    }

    &.cancel{
      text-transform:uppercase;
      color:$mainColor;
    }
  }
}

.userPopup{
  text-align:center;
  font-size:18px;

  .logout{
    text-transform:uppercase;
    color:$mainColor;
    text-decoration:none;
  }

  img{
    width:200px;
    border:1px solid #ececec;
    height:200px;
    border-radius:100px;
    display:block;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:20px;
  }
}

@import "_colors";

body{
  background-color:#fcfcfc;
}

*{
  box-sizing:border-box;
}

.headerProfile{
  width:70px;
  height:70px;
  position:absolute;
  top:-100px;
  z-index:9999999;
  right:10px;
  border-radius:35px;
  cursor:pointer;
}

.header{
  text-align:center;

  img{
    width:200px;
    margin-top:20px;
    margin-left:auto;
    margin-right:auto;

  }

  h1{
    margin-top:0px;
    padding-top:5px;

    font-family:console,monospace;
  }

  .menuToggle{
    display:none;
  }
}



.container{
  border:1px solid #ececec;
  background-color:#fff;
  display:block;
  width:1200px;
  max-width:100%;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:30px;
  .userInfo{
    background-color:#ececec;
    padding:20px;
    text-align:right;
  }



  .holder{
    padding:20px;
    padding-left:330px;
    position:relative;
    min-height:80vh;



    .menu{
      list-style:none;
      position:absolute;
      top:0px;
      left:0px;
      margin:0px;
      padding:0px;
      border-right:1px solid #ececec;
      height:100%;
      background-color:#fff;

      li{
        list-style:none;
        margin:0px;
        padding:0px;
        width:300px;

        a{
          display:block;
          padding:20px;
          border-top:1px solid #ececec;
          text-decoration:none;

          &.active{
            background-color:purple;
            color:#fff;
            font-weight:bold;
          }
        }

        &:first-child{
          a{
            border-top:0px;
          }
        }

        &.sublink{
          
          a{
            padding:10px;
            border-top:1px dotted #ececec !important;
            border-bottom:0px;
            padding-left:30px;

            &.active{
              background-color:#ececec;
              color:#000;
              font-weight:bold;
            }
          }
        }
      }
    }
  }
}

.loginForm{
  margin-left:auto;
  margin-right:auto;
  padding:20px;

  .screenContentHolder.inline{
    box-shadow:none !important;
  }
}

@media screen and (max-width:600px){

  .headerProfile{
    position:fixed;
    top:5px;
    right:5px;
  }

  .header{
    position:fixed;
    top:0px;
    left:0px;
    height:80px;
    z-index:99999;
    width:100%;
    background-color:#fcfcfc;
    border-bottom:1px solid #ececec;

    h1{
      font-size:20px;
      padding-top:0px;
    }

    img{
      margin-top:10px;

    }

    .menuToggle{
      position:absolute;
      left:20px;
      top:12px;
      width:50px;
      height:50px;
      background-color:$mainColor;
      cursor:pointer;
      display:flex;
      justify-content: center;
      align-items: center;

      svg{
        color:#fff;
        width:40px;
      }

      svg.close{
        display:none;
      }

      &.close{
        svg.open{
          display:none;
        }

        svg.close{
          display:block;
        }
      }
    }
  }
  .container{
    margin-top:70px;
    .holder{
      padding-left:0px;
      padding:10px;


      .menu{
        left:-100%;
        width:100vw;
        position:fixed;
        transition:left 0.3s ease-in-out;
        z-index:4;
        top:80px;
        height:calc(100vh - 70px);
        overflow-y:auto;


        &.show::after{
          content:'';
          display:block;
          background:rgba(255,255,255,0.9);
          position:fixed;
          z-index:2;
          top:0px;
          left:0px;
          width:100vw;
          height:100%;
        }


        &.show{
          left:0px;
        }

        li{
          position:relative;
          z-index:4;
          width:100%;
          text-align:center;
        }
      }
    }


  }
}

.userBlock{
  display:block;
  position:relative;
  min-height:60px;
  padding-left:70px;
  max-width:400px;
  text-align:left;


  img{
    position:absolute;
    top:0px;
    left:10px;
    width:50px;
    height:50px;
    border-radius:25px;
  }

  b{
    display:block;
    padding-top:4px;
  }
}

.button2{
  cursor:pointer;
  border:0px;
  padding:14px;
  padding-left:24px;
  padding-right:24px;
  background:transparent;
  text-transform:uppercase;
  margin:10px;
  transition:all 0.3s ease-in-out;

  &:hover{
    transform:scale(1.1);
  }

  &.red{
    color:red;
  }
}

.eventSearch{
    padding:20px;
    .searchbar{
        width:100%;
        padding-right:120px;
        position:relative;
        border:1px solid #ececec;
        border-radius:10px;
        overflow:hidden;
        font-size:16px;
        input[type="text"]{
            padding:10px;
            width:100%;
            border:0px;
            outline:0px;

        }

        button{
            position:absolute;
            right:0px;
            top:0px;
            padding:10px;
            text-align:center;
            border:0px;
            width:120px;
            background-color:purple;
            color:#fff;
            cursor:pointer;

            &:disabled{
                opacity:0.3;
                cursor:default;
            }
        }
    }

    label{
        padding:10px;
        display:block;
    }
}